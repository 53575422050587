import indexStyles from '../sass/index.scss';
let commons = require('./utils/commons');
import mainImageIMG from '../img/main-image.png';
import mainImageXsIMG from '../img/main-image-xs.png';
import brand1 from '../img/brand-1.png';
import brand2 from '../img/brand-2.png';
import brand3 from '../img/brand-3.png';
import brand4 from '../img/brand-4.png';
import brand5 from '../img/brand-5.png';
import brand6 from '../img/brand-6.png';
import brand7 from '../img/brand-7.png';
import brand8 from '../img/brand-8.png';
import brand9 from '../img/brand-9.png';
import brand10 from '../img/brand-10.png';
import review1IMG from '../img/review-1.png';
import review2IMG from '../img/review-2.png';
import review3IMG from '../img/review-3.png';
import aboutIMG from '../img/about.png';
import mapIMG from '../img/map.png';
